import { DateAccuracy } from 'src/types/dog';

export const getDateAccuracy = (date: string | null) => {
  if (date === null) {
    return null;
  }

  if (!date) {
    return DateAccuracy.NONE;
  } else {
    const dateArr = date.split('-');
    if (dateArr.length === 1) {
      return DateAccuracy.YEAR;
    } else if (dateArr.length === 2) {
      return DateAccuracy.MONTH;
    } else {
      return DateAccuracy.DAY;
    }
  }
};

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
] as const;

/**
 *
 * @param raw
 * @param accuracy
 * @returns {number[]} [year, month, day, hour, min, sec]
 */
export const getDateParamsByAccuracy = (
  raw: string,
  accuracy: DateAccuracy | null,
): [number, number, number, number, number, number] | null => {
  if (!accuracy || accuracy === DateAccuracy.NONE) {
    return null;
  }

  if (accuracy === DateAccuracy.YEAR) {
    const splitedDate = raw.split('-');
    const year = +splitedDate[0];
    return [year, 0, 1, 0, 0, 0];
  }
  if (accuracy === DateAccuracy.MONTH) {
    const splitedDate = raw.split('-');
    const year = +splitedDate[0];
    const month = +splitedDate[1] - 1;
    return [year, month, 1, 0, 0, 0];
  }
  const splitedDate = raw.split('-');
  const year = +splitedDate[0];
  const month = +splitedDate[1] - 1;
  const date = +splitedDate[2];
  return [year, month, date, 0, 0, 0];
};

export const getDateWithAccuracy = (raw: string | null, accuracy: DateAccuracy | null) => {
  if (!raw) {
    return null;
  }

  const dateParams = getDateParamsByAccuracy(raw, accuracy);
  if (!dateParams) {
    return null;
  }

  const date = new Date(...dateParams);
  return date;
};

export const convertDateToStringByAccuray = (raw: string | null, accuracy: DateAccuracy | null) => {
  const date = getDateWithAccuracy(raw, accuracy);
  if (!date) {
    return null;
  }

  if (accuracy === DateAccuracy.NONE) {
    return null;
  } else if (accuracy === DateAccuracy.YEAR) {
    return '' + date.getFullYear();
  } else if (accuracy === DateAccuracy.MONTH) {
    return `${monthNames[date.getMonth()]}, ${date.getFullYear()}`;
  } else {
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  }
};

export const calculateDiffYearAndMonth = (startDate: Date) => {
  const today = new Date();

  let yearDiff = today.getFullYear() - startDate.getFullYear();
  const dateDiff = today.getDate() - startDate.getDate();
  let m = today.getMonth() - startDate.getMonth();
  if (m < 0 || (m === 0 && dateDiff < 0)) {
    yearDiff--;
    if (m === 0) {
      m = 11;
    } else {
      m = 12 + m;
      if (dateDiff < 0) {
        m--;
      }
    }
    return { yearDiff, monthDiff: m };
  }

  if (dateDiff < 0) {
    m--;
  }

  return { yearDiff, monthDiff: m };
};

export const calculateAgeByAccuracy = (raw: string | null, accuracy: DateAccuracy | null) => {
  const birthDate = getDateWithAccuracy(raw, accuracy);
  if (!birthDate) {
    return null;
  }

  const { yearDiff, monthDiff } = calculateDiffYearAndMonth(birthDate);

  if (monthDiff) {
    return `${yearDiff ? `${yearDiff} ${yearDiff > 1 ? 'yrs' : 'yr'} ` : ''}${monthDiff} ${
      monthDiff > 1 ? 'mos' : 'mo'
    }`;
  }

  return `${yearDiff ? `${yearDiff} ${yearDiff > 1 ? 'yrs' : 'yr'} ` : 'less than a month'}`;
};

export const calculatePassedDate = (raw: string | null, accuracy: DateAccuracy | null) => {
  const startDate = getDateWithAccuracy(raw, accuracy);
  if (!startDate) {
    return null;
  }

  const today = new Date();
  const diffDate = today.getTime() - startDate.getTime();

  return Math.ceil(diffDate / (1000 * 60 * 60 * 24));
};

/**
 * beatify time diff from now
 * ex) now, 1m, 2h, 4d, 123w
 * @param d date string
 */
export const beautifyTimeDiffFromNow = (d: string | number) => {
  const date = new Date(d);
  const now = new Date();

  const timeDiffMin = Math.floor((now.getTime() - date.getTime()) / (60 * 1000));
  if (timeDiffMin <= 0) {
    return 'now';
  } else if (timeDiffMin < 60) {
    return `${timeDiffMin}m`;
  } else if (timeDiffMin < 60 * 24) {
    return `${Math.floor(timeDiffMin / 60)}h`;
  } else if (timeDiffMin < 60 * 24 * 7) {
    return `${Math.floor(timeDiffMin / (60 * 24))}d`;
  } else {
    return `${Math.floor(timeDiffMin / (60 * 24 * 7))}w`;
  }
};

interface BirthdayRangeOption {
  noLimit: boolean;
}

export const getBirthdayRangeByAge = (
  age: number,
  option?: BirthdayRangeOption,
): [string, string] => {
  const now = new Date();

  if (age < 1) {
    return [
      `${now.getFullYear() - (age + 1)}-${now.getMonth() + 1}-${now.getDate()}`,
      `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`,
    ];
  } else if (option?.noLimit) {
    return [
      `${now.getFullYear() - 30}-${now.getMonth() + 1}-${now.getDate()}`,
      `${now.getFullYear() - (age + 1)}-${now.getMonth() + 1}-${now.getDate()}`,
    ];
  } else {
    return [
      `${now.getFullYear() - (age + 1)}-${now.getMonth() + 1}-${now.getDate()}`,
      `${now.getFullYear() - age}-${now.getMonth() + 1}-${now.getDate()}`,
    ];
  }
};

export const getBirthdayByDetailAge = (age: number, month: number) => {
  const now = new Date();

  const monthDiff = now.getMonth() + 1 - month;
  if (monthDiff < 1) {
    return `${now.getFullYear() - age - 1}-${12 + monthDiff}-${now.getDate()}`;
  }
  return `${now.getFullYear() - age}-${monthDiff}-${now.getDate()}`;
};

export function timeAgo(date: string) {
  const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'week', seconds: 604800 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ];

  const interval = intervals.find((i) => i.seconds < seconds);
  if (!interval) return 'just now';

  const count = Math.floor(seconds / interval.seconds);
  return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
}
