import { Sender } from 'sendbird';
import { SendBirdSelectors } from 'sendbird-uikit';
import { create } from 'zustand';

export interface InAppNotificationPayload {
  sender: Sender | null;
  message: string;
  channelUrl?: string;
  data?: string | null;
}

type Mode = string | null;
export interface InAppNotificationStore {
  isOpen: boolean;
  mode: Mode;
  payload: InAppNotificationPayload | null;
  showNotification: (payload: InAppNotificationPayload, mode: Mode) => void;
  hideNotification: () => void;
}

const useInAppNotificationStore = create<InAppNotificationStore>((set) => ({
  isOpen: false,
  mode: null,
  payload: null,
  showNotification: (payload, mode) => set({ isOpen: true, payload, mode }),
  hideNotification: () => set({ isOpen: false, payload: null, mode: null }),
}));

export default useInAppNotificationStore;
