import { useEffect } from 'react';

import { connectSendbird, markAsReadChannelMessages, SENDBIRD_ADMIN_ID } from 'lib/sendbird';
import useUnreadMessageStore from 'src/states/unread-message';

const useReadingAdminChannel = (myId: string) => {
  const setHasUnreadMessage = useUnreadMessageStore((state) => state.updateHasUnreadMessage);

  useEffect(() => {
    (async () => {
      try {
        const [, sdk] = await Promise.all([
          markAsReadChannelMessages(`${myId}`, SENDBIRD_ADMIN_ID),
          connectSendbird(`${myId}`),
        ]);
        const gp = new sdk.GroupChannelTotalUnreadMessageCountParams();
        const count = await sdk.getTotalUnreadMessageCount(gp);
        setHasUnreadMessage(count > 0);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [myId, setHasUnreadMessage]);
};

export default useReadingAdminChannel;
