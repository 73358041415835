import styled from 'styled-components';

import useMessageStore from 'src/states/message';
import { useEffect } from 'react';

const Box = styled.div`
  position: absolute;
  bottom: 12px;
  left: calc(50% - 150px);
  width: 300px;
  padding: 12px 16px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 12px 24px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 99;
`;

const StyledText = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const BottomToastMessage = () => {
  const [message, mode, hideMessage] = useMessageStore((state) => [
    state.message,
    state.mode,
    state.hideMessage,
  ]);
  const isOpen = message && mode === 'bottom';

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => hideMessage(), 3000);
    }
  }, [isOpen, hideMessage]);

  if (isOpen) {
    return (
      <Box>
        <StyledText>{message}</StyledText>
      </Box>
    );
  }

  return null;
};

export default BottomToastMessage;
