import { concat, sort } from 'ramda';

import { Dog, Tag, TagCategory } from 'src/types/dog';
import { calculateAgeByAccuracy } from 'src/utils/date';

export const sortTagsById = (tags: Omit<Tag, 'createdAt'>[]) => {
  return sort<Omit<Tag, 'createdAt'>>((a, b) => a.id - b.id)(tags);
};

export const filterTagsByCategory = (tags: Tag[], categories: TagCategory[]) => {
  const filtered = tags.filter((tag) => categories.includes(tag.category));
  return filtered;
};

export const sortTagsByCategory = (tags: Omit<Tag, 'createdAt'>[]) => {
  const categoryMap = {
    [TagCategory.PLAYSTYLE]: 6,
    [TagCategory.ENERGY_LEVEL]: 5,
    [TagCategory.PERSONALITY_V2]: 4,
    [TagCategory.PERSONALITY]: 3,
    [TagCategory.FOODS]: 2,
    [TagCategory.ACTIVITIES]: 1,
    [TagCategory.HABITS]: 0,
  };
  const presetTags = tags.filter((tag) => tag.isPreset);
  const customTags = tags.filter((tag) => !tag.isPreset);
  const sortedTags = concat(presetTags, customTags).sort((a, b) => {
    if (categoryMap[a.category] - categoryMap[b.category] < 0) {
      return 1;
    } else if (categoryMap[a.category] - categoryMap[b.category] > 0) {
      return -1;
    }
    return 0;
  });

  return sortedTags;
};

export const getCommonTagsWithDogFromFactors = (
  factors: string[],
  factorValues: any[],
  opponentDog: Dog,
) => {
  const tags: any[] = [];
  factors.forEach((factor, j) => {
    switch (factor) {
      case 'WEIGHT':
        tags.push(`${factorValues[j]}lbs`);
        break;
      case 'AGE':
        tags.push(calculateAgeByAccuracy(opponentDog.dateOfBirth, opponentDog.dobAccuracy));
        break;
      case 'PLAYSTYLE':
        factorValues[j].forEach((playstyle: string) => tags.push(playstyle));
        break;
      case 'ENERGY_LEVEL':
      case 'BREED':
        tags.push(factorValues[j]);
        break;
    }
  });

  return tags;
};
