import Cookies, { CookieSetOptions } from 'universal-cookie';

import api, { APIResponse } from 'src/api';
import { SignInResult, SignUpResult } from 'src/types/auth';
import { SocialMediaType, User } from 'src/types/user';
import { getUserToken, setTokensInCookie, setUserDataForGtmAfterSign } from 'src/utils/auth';
import { EmptyTokenError } from 'src/utils/error';

export const signUpWithEmail = async (username: string, password: string) => {
  const uuid = localStorage.getItem('uuid') ?? undefined;
  const { data } = await api.post<APIResponse<SignInResult>>('/auth/signup', {
    username,
    password,
    uuid,
  });
  const { accessToken, user } = data.data;

  setTokensInCookie(accessToken, user.sendbirdToken!);
  localStorage.removeItem('uuid');
  setUserDataForGtmAfterSign(accessToken, user.id, 'sign_up_email');

  return user;
};

export const signUpWithEmailAndInviationCode = async (
  username: string,
  password: string,
  invitationCode: string,
) => {
  const { data } = await api.post<APIResponse<SignUpResult>>('/auth/signup/invitation', {
    username,
    password,
    invitationCode,
  });
  const { accessToken, user } = data.data;

  setTokensInCookie(accessToken, user.sendbirdToken!);
  setUserDataForGtmAfterSign(accessToken, user.id, 'sign_up_email');

  return user;
};

export const signInWithEmail = async (username: string, password: string) => {
  const uuid = localStorage.getItem('uuid') ?? undefined;
  const { data } = await api.post<APIResponse<SignInResult>>('/auth/login', {
    username,
    password,
    uuid,
  });
  const { accessToken, user } = data.data;

  setTokensInCookie(data.data.accessToken, data.data.user.sendbirdToken!);
  localStorage.removeItem('uuid');
  setUserDataForGtmAfterSign(accessToken, user.id, 'sign_in_email');

  return user;
};

export const hasSameEmailUser = async (email: string) => {
  try {
    await api.head(`/users?email=${email}`);
    return true;
  } catch {
    return false;
  }
};

export const getMyData = async (token: string) => {
  const { data } = await api.get<APIResponse<User>>('/auth/me', {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.data;
};

export const sendResetPasswordEmail = async (email: string) => {
  const result = await api.get(`/auth/email/resetPassword/${email}`);
  return result.status === 200;
};

export const checkResetPasswordUrl = async (email: string, token: string) => {
  try {
    await api.head<void>(`/auth/email/resetPassword?email=${email}&token=${token}`);
    return true;
  } catch {
    return false;
  }
};

interface ChangePasswordBody {
  email: string;
  token: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const resetPassword = async (passwords: ChangePasswordBody) => {
  const result = await api.post(`/auth/email/resetPassword`, passwords);
  return result.status === 201;
};

export const verifyEmail = async (email: string, token: string) => {
  return await api.post(`/auth/email/verify/${email}?token=${token}`);
};

export const sendVerificationEmail = async (email: string) => {
  return await api.get(`/auth/email/verify/${email}`);
};

interface CredentialBody {
  userId: number;
  authType: 'facebook' | 'instagram';
  identifier: string;
  secret?: string;
  profileUrl: string;
  email?: string;
  username?: string;
  name?: string;
  lastName?: string;
  firstName?: string;
  expiresAt?: string;
}
export const createCredential = async (credential: CredentialBody) => {
  const token = getUserToken();
  const result = await api.post('/auth/credentials', credential, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const removeCredential = async (authType: SocialMediaType) => {
  const token = getUserToken();
  if (token) {
    await api.delete(`/auth/credentials/${authType}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return;
  }

  throw new EmptyTokenError();
};
