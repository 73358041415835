interface IconResources {
  openedEye: string;
  closedEye: string;
  google: string;
  hamburgerMenu: string;
  circlePlus: string;
  circlePlusGray: string;
  home: string;
  cards: string;
  user: string;
  signin: string;
  signOut: string;
  circleUser: string;
  plus: string;
  arrowRight: string;
  arrowRightDisabled: string;
  maleGray: string;
  femaleGray: string;
  maleWhite: string;
  femaleWhite: string;
  maleBlack: string;
  femaleBlack: string;
  phDog: string;
  optionDown: string;
  checkBlack: string;
  arrowLeftDisabled: string;
  location: string;
  optionDownGray: string;
  close: string;
  closeThin: string;
  circleCheck: string;
  spinner: string;
  share: string;
  closeWhite: string;
  link: string;
  dog: string;
  settings: string;
  exclamation: string;
  flipTheCard: string;
  edit: string;
  trash: string;
  cameraWhite: string;
  editBalck: string;
  currentLocation: string;
  locationBlack: string;
  globe: string;
  plusGray: string;
  enter: string;
  paperPlane: string;
  message: string;
  messageWhite: string;
  paperPlaneGray: string;
  checkGray: string;
  save: string;
  saveFilled: string;
  messageYellow: string;
  paperPlaneWhite: string;
  dropdown: string;
  dropdownBlack: string;
  phDogFill: string;
  informationCircle: string;
  smileFace: string;
  feedback: string;
  instagram: string;
  facebook: string;
  carot: string;
  checkSelected: string;
  checkDeselected: string;
  yellowCheck: string;
  yellowArrowRight: string;
  closeBlue: string;
  shareWhite: string;
  linkWhite: string;
  carotRight: string;
  swipeNext: string;
  informationGray: string;
  heartLine: string;
  heartFill: string;
  moreWite: string;
  flagShadowFill: string;
  flagShadowLine: string;
  docShadowFill: string;
  carotRightWhite: string;
  filter: string;
  filterWhite: string;
  reset: string;
  closeGray: string;
  searchGray: string;
  envelop: string;
  apple: string;
  dogSolesFill: string;
  plusFill: string;
  dogBody: string;
  back: string;
  dropdownThick: string;
  crownFill: string;
  private: string;
  public: string;
  dropdownSmall: string;
  trashcan: string;
  uploadclip: string;
  redPin: string;
  group: string;
  shieldYellowFill: string;
}

interface ImageResources {
  dogFace: string;
  logo: string;
  frontMock: string;
  backMock: string;
  mainMeta: string;
  emptySavedCard: string;
  gloomyDog: string;
  wrestling: string;
  lonelyWolf: string;
  tugging: string;
  nipping: string;
  chasing: string;
  cheerleading: string;
  playstyles: string;
  reactionLovely: string;
  reactionAdorable: string;
  reactionCute: string;
  illust1: string;
  illust2: string;
  illust3: string;
  illust4: string;
  illust5: string;
  tSocialButterfly: string;
  tRelentlessDefender: string;
  tLoneWolf: string;
  tDoggyPleaser: string;
  tCautiousWorrier: string;
  tAssertiveController: string;
  selfPupCover: string;
  testAnalyzingSpinner: string;
  metaAssertiveController: string;
  metaRelentlessDefender: string;
  metaLoneWolf: string;
  metaDoggyPleaser: string;
  metaCautiousWorrier: string;
  metaSocialButterfly: string;
  metaGuestSelfPup: string;
  aMakeCard: string;
  bOtherCards: string;
  disappointedDog: string;
  typeTags: string;
  emailSending: string;
  distanceWeight: string;
  logoWithDesc: string;
  momo: string;
  momoCardFront: string;
  momoCardBack: string;
  confetti: string;
  appLogo: string;
  launchCardBg: string;
  appleStoreBanner: string;
  googleStoreBanner: string;
  meetMyDogChallenge: string;
  SendEmailModalLong: string;
  SendEmailModalShort: string;
  SendTextmessageModal: string;
  randomCover1: string;
  randomCover2: string;
  randomCover3: string;
  randomCover4: string;
  randomCover5: string;
}

interface Resources {
  icons: IconResources;
  images: ImageResources;
}

const resources: Resources = {
  icons: {
    openedEye: '/resources/icons/eye-open.svg',
    closedEye: '/resources/icons/eye-close.svg',
    google: '/resources/icons/google.svg',
    hamburgerMenu: '/resources/icons/hamburger-menu.svg',
    circlePlus: '/resources/icons/circle-plus.svg',
    circlePlusGray: '/resources/icons/circle-plus-gray.svg',
    home: '/resources/icons/home.svg',
    cards: '/resources/icons/cards.svg',
    user: '/resources/icons/user.svg',
    group: '/resources/icons/group.svg',
    signin: '/resources/icons/signin.svg',
    signOut: '/resources/icons/signout.svg',
    circleUser: '/resources/icons/circle-user.svg',
    plus: '/resources/icons/plus.svg',
    arrowRight: '/resources/icons/arrow-right.svg',
    arrowRightDisabled: '/resources/icons/arrow-right-disabled.svg',
    maleGray: '/resources/icons/male-gray.svg',
    femaleGray: '/resources/icons/female-gray.svg',
    maleWhite: '/resources/icons/male-white.svg',
    femaleWhite: '/resources/icons/female-white.svg',
    maleBlack: '/resources/icons/male-black.svg',
    femaleBlack: '/resources/icons/female-black.svg',
    phDog: '/resources/icons/ph-dog.svg',
    optionDown: '/resources/icons/option-down.svg',
    checkBlack: '/resources/icons/check-black.svg',
    arrowLeftDisabled: '/resources/icons/arrow-left-disabled.svg',
    location: '/resources/icons/location.svg',
    optionDownGray: '/resources/icons/option-down-gray.svg',
    close: '/resources/icons/close.svg',
    closeThin: '/resources/icons/close-thin.svg',
    circleCheck: '/resources/icons/circle-check.svg',
    spinner: '/resources/icons/spinner.svg',
    share: '/resources/icons/share.svg',
    closeWhite: '/resources/icons/close-white.svg',
    link: '/resources/icons/link.svg',
    dog: '/resources/icons/dog.svg',
    settings: '/resources/icons/settings.svg',
    exclamation: '/resources/icons/exclamation.svg',
    flipTheCard: '/resources/icons/flip-the-card.svg',
    edit: '/resources/icons/edit.svg',
    trash: '/resources/icons/trash.svg',
    cameraWhite: '/resources/icons/camera-white.svg',
    editBalck: '/resources/icons/edit-black.svg',
    currentLocation: '/resources/icons/current-location.svg',
    locationBlack: '/resources/icons/location-black.svg',
    globe: '/resources/icons/globe.svg',
    plusGray: '/resources/icons/plus-gray.svg',
    enter: '/resources/icons/enter.svg',
    paperPlane: '/resources/icons/paper-plane.svg',
    message: '/resources/icons/message.svg',
    messageWhite: '/resources/icons/message-white.svg',
    paperPlaneGray: '/resources/icons/paper-plane-gray.svg',
    checkGray: '/resources/icons/check-gray.svg',
    save: '/resources/icons/save.svg',
    saveFilled: '/resources/icons/save-filled.svg',
    messageYellow: '/resources/icons/message-yellow.svg',
    paperPlaneWhite: '/resources/icons/paper-plane-white.svg',
    dropdown: '/resources/icons/dropdown.svg',
    dropdownBlack: '/resources/icons/dropdown-black.svg',
    phDogFill: '/resources/icons/ph-dog-fill.svg',
    informationCircle: '/resources/icons/information-circle.svg',
    smileFace: '/resources/icons/smile-face.svg',
    feedback: '/resources/icons/feedback.svg',
    instagram: '/resources/icons/instagram.svg',
    facebook: '/resources/icons/facebook.svg',
    carot: '/resources/icons/carot.svg',
    checkDeselected: '/resources/icons/check-deselected.svg',
    checkSelected: '/resources/icons/check-selected.svg',
    yellowCheck: '/resources/icons/yellow-check.svg',
    yellowArrowRight: '/resources/icons/yellow-arrow-right.svg',
    closeBlue: '/resources/icons/blue-close.svg',
    shareWhite: '/resources/icons/share-white.svg',
    linkWhite: '/resources/icons/link-white.svg',
    carotRight: '/resources/icons/carot-right.svg',
    swipeNext: '/resources/icons/swipe-next.svg',
    informationGray: '/resources/icons/information-gray.svg',
    heartLine: '/resources/icons/heart-outlined.png',
    heartFill: '/resources/icons/heart-fill.svg',
    moreWite: '/resources/icons/more-white.svg',
    flagShadowFill: '/resources/icons/flag-shadow-fill.svg',
    flagShadowLine: '/resources/icons/flag-shadow-line.svg',
    docShadowFill: '/resources/icons/doc-shadow-fill.svg',
    carotRightWhite: '/resources/icons/carot-right-white.svg',
    filter: '/resources/icons/filter.svg',
    filterWhite: '/resources/icons/filter-white.svg',
    reset: '/resources/icons/reset.svg',
    closeGray: '/resources/icons/close-gray.svg',
    searchGray: '/resources/icons/search-gray.svg',
    envelop: '/resources/icons/envelop.svg',
    apple: '/resources/icons/apple.svg',
    dogSolesFill: '/resources/icons/dog-soles-fill.svg',
    plusFill: '/resources/icons/plus-fill.svg',
    dogBody: '/resources/icons/dog-body.svg',
    back: '/resources/icons/back.svg',
    dropdownThick: '/resources/icons/dropdown-thick.svg',
    crownFill: '/resources/icons/crownFill.svg',
    private: '/resources/icons/private.svg',
    public: '/resources/icons/public.svg',
    dropdownSmall: '/resources/icons/dropdown-small.svg',
    trashcan: '/resources/icons/trashcan.svg',
    uploadclip: '/resources/icons/uploadclip.svg',
    redPin: '/resources/icons/red-pin.svg',
    shieldYellowFill: '/resources/icons/shield-yellow-fill.svg',
  },
  images: {
    dogFace: '/resources/images/dog-face.png',
    logo: '/resources/images/logo.png',
    frontMock: '/resources/images/front-mock.png',
    backMock: '/resources/images/back-mock.png',
    mainMeta: '/resources/images/main-meta.png',
    emptySavedCard: '/resources/images/empty-saved-card.png',
    gloomyDog: '/resources/images/gloomy-dog.png',
    wrestling: '/resources/images/wrestling.png',
    lonelyWolf: '/resources/images/lonely-wolf.png',
    tugging: '/resources/images/tugging.png',
    nipping: '/resources/images/nipping.png',
    chasing: '/resources/images/chasing.png',
    cheerleading: '/resources/images/cheerleading.png',
    playstyles: '/resources/images/playstyles.png',
    reactionLovely: '/resources/images/lovely.png',
    reactionAdorable: '/resources/images/adorable.png',
    reactionCute: '/resources/images/cute.png',
    illust1: '/resources/images/illust-1.png',
    illust2: '/resources/images/illust-2.png',
    illust3: '/resources/images/illust-3.png',
    illust4: '/resources/images/illust-4.png',
    illust5: '/resources/images/illust-5.png',
    tAssertiveController: '/resources/images/t_assertive_controller.png',
    tCautiousWorrier: '/resources/images/t_cautious_worrier.png',
    tDoggyPleaser: '/resources/images/t_doggy_pleaser.png',
    tLoneWolf: '/resources/images/t_lone_wolf.png',
    tRelentlessDefender: '/resources/images/t_relentless_defender.png',
    tSocialButterfly: '/resources/images/t_social_butterfly.png',
    selfPupCover: '/resources/images/self-pup-cover.png',
    testAnalyzingSpinner: '/resources/images/test-analyzing.png',
    metaAssertiveController: '/resources/image/meta-assertive-controller.png',
    metaCautiousWorrier: '/resources/image/meta-cautious-worrier.png',
    metaDoggyPleaser: '/resources/image/meta-dog-pleaser.png',
    metaLoneWolf: '/resources/image/meta-lone-wolf.png',
    metaRelentlessDefender: '/resources/image/meta-relentless-defender.png',
    metaSocialButterfly: '/resources/image/meta-social-butterfly.png',
    metaGuestSelfPup: '/resources/image/meta-guest-self-pup',
    aMakeCard: '/resources/images/a_make_card.png',
    bOtherCards: '/resources/images/b_other_cards.png',
    disappointedDog: '/resources/images/disappointed_dog.png',
    typeTags: '/resources/images/type-tags.png',
    emailSending: '/resources/images/email-sending.png',
    distanceWeight: '/resources/images/distance-weight.png',
    logoWithDesc: '/resources/images/logo2.png',
    momo: '/resources/images/momo.png',
    momoCardBack: '/resources/images/momo-back.png',
    momoCardFront: '/resources/images/momo-front.png',
    confetti: '/resources/images/confetti.png',
    appLogo: '/resources/images/app-logo.png',
    launchCardBg: '/resources/images/launch-card-bg.png',
    appleStoreBanner: '/resources/images/appstore-banner.png',
    googleStoreBanner: '/resources/images/playstore-banner.png',
    meetMyDogChallenge: '/resources/images/meetmydogchallenge.png',
    SendEmailModalLong: '/resources/images/send-email-modal-long.png',
    SendEmailModalShort: '/resources/images/send-email-modal-short.png',
    SendTextmessageModal: '/resources/images/send-textmessage-modal.png',
    randomCover1: '/resources/images/random-cover-1.png',
    randomCover2: '/resources/images/random-cover-2.png',
    randomCover3: '/resources/images/random-cover-3.png',
    randomCover4: '/resources/images/random-cover-4.png',
    randomCover5: '/resources/images/random-cover-5.png',
  },
};

export default resources;
