import Image from 'next/legacy/image';
import { useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import Button from 'src/components/buttons/Button';
import FullModal from 'src/components/modals/FullModal';
import resources from 'src/resources';
import useAuth from 'src/hooks/useAuth';
import useUnreadMessageStore from 'src/states/app-launch-modal';
import { pushEventToGTM } from 'src/utils/gtm';

import PlaydatesAppLaunchModalContent from '../playdates/PlaydatesAppLaunchModalContent';
import AppLaunchModalContent from '../AppLaunchModalContent';

const APP_LAUNCH_MODAL_CONTENT = {
  default: <AppLaunchModalContent />,
  playdates: <PlaydatesAppLaunchModalContent />,
};

const AppLaunchModal = () => {
  const {
    openAppLaunchModal,
    updateOpenAppLaunchModal,
    appLaunchModalType,
    updateAppLaunchModalType,
  } = useUnreadMessageStore();
  const { me } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!openAppLaunchModal) {
      updateAppLaunchModalType('default');
    }
  }, [openAppLaunchModal]);

  useEffect(() => {
    const isModalPossible =
      !!me &&
      !router.pathname.match(
        /^\/(auth|account|dog|new-features|services|test|onboarding|_error|articles)/,
      );

    if (
      typeof window !== 'undefined' &&
      window.ReactNativeWebView === undefined &&
      isModalPossible
    ) {
      if (sessionStorage.getItem('app_modal') !== 'true') {
        sessionStorage.setItem('app_modal', 'true');
        updateOpenAppLaunchModal(true);
      }
    }
  }, [me, router.pathname]);

  if (!openAppLaunchModal) {
    return null;
  }

  return (
    <FullModal
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px',
      }}
      onClickBlank={() => updateOpenAppLaunchModal(false)}
    >
      <Box onClick={(e) => e.stopPropagation()}>
        <BackgroundImage>
          <Image src={resources.images.appLogo} width={100} height={100} alt="MMDC" unoptimized />
        </BackgroundImage>
        <div style={{ padding: '20px 14px' }}>
          <StyledText>{APP_LAUNCH_MODAL_CONTENT[appLaunchModalType]}</StyledText>

          <div style={{ padding: '0 12px' }}>
            {isMobile ? (
              <Button
                onClick={() => {
                  pushEventToGTM('click_get_the_app', { 'custom-user_id': me?.id ?? 0 });
                  window.open('https://onelink.to/kad8mk');
                }}
              >
                Get the App!
              </Button>
            ) : (
              <ButtonContainer>
                <BannerButton
                  onClick={() => {
                    pushEventToGTM('click_get_the_app_apple', { 'custom-user_id': me?.id ?? 0 });
                    window.open('https://apps.apple.com/us/app/id6443806747');
                  }}
                >
                  <Image
                    src={resources.images.appleStoreBanner}
                    layout="fill"
                    objectFit="contain"
                  />
                </BannerButton>
                <BannerButton
                  onClick={() => {
                    pushEventToGTM('click_get_the_app_google', { 'custom-user_id': me?.id ?? 0 });
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.momoproject.mmdc',
                    );
                  }}
                >
                  <Image
                    src={resources.images.googleStoreBanner}
                    layout="fill"
                    objectFit="contain"
                  />
                </BannerButton>
              </ButtonContainer>
            )}
          </div>
        </div>
      </Box>

      <StyledClose onClick={() => updateOpenAppLaunchModal(false)}>
        <StyledCloseText>close</StyledCloseText>
      </StyledClose>
    </FullModal>
  );
};

export default AppLaunchModal;

const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  max-width: 320px;
  margin: 0 auto;
`;

const BackgroundImage = styled.div`
  height: 250px;
  background-image: url(${resources.images.confetti});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.p`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 24px;

  b {
    color: #ffc93f;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const BannerButton = styled.button`
  position: relative;
  margin: 0;
  background-color: #ffffff;
  padding: 0;
  flex: 1;
  height: 40px;
`;

const StyledClose = styled.div`
  margin-top: 12px;
  padding: 8px;
  cursor: pointer;
`;

const StyledCloseText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: underline;
`;
