import Image from 'next/legacy/image';
import styled, { keyframes } from 'styled-components';

import resources from 'src/resources';

const spin = keyframes`
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg)
  }
`;

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  animation: ${spin} 0.5s linear infinite;
  user-select: none;
`;

interface Props {
  width: number;
  height: number;
}

const Spinner = (props: Props) => {
  return (
    <Wrapper>
      <Image
        src={resources.icons.spinner}
        alt="loading"
        width={props.width}
        height={props.height}
        priority
      />
    </Wrapper>
  );
};

export default Spinner;
