import { create } from 'zustand';

type Mode = 'top' | 'bottom';
interface MessageStore {
  message: string | null;
  icon: string | null;
  mode?: Mode;
  showMessage: (msg: string, mode: Mode, icon?: string) => void;
  hideMessage: () => void;
}

const useMessageStore = create<MessageStore>((set) => ({
  message: null,
  icon: null,
  showMessage: (message: string, mode: Mode, icon?: string) => {
    set({ message, mode, icon });
  },
  hideMessage: () => set({ message: null, mode: undefined }),
}));

export default useMessageStore;
