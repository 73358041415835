import styled from 'styled-components';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';

import FullModal from 'src/components/modals/FullModal';
import Button from 'src/components/buttons/Button';
import useInAppNotificationStore from 'src/states/inapp-notification';
import { Dog, Gender } from 'src/types/dog';
import { imageKitLoader } from 'src/utils/imagekit.loader';
import { calculateAgeByAccuracy } from 'src/utils/date';
import { pushEventToGTM } from 'src/utils/gtm';
import useMatchInfoStore from 'src/states/match-info';
import useReadingAdminChannel from 'src/hooks/useReadingAdminChannel';
import { createOrOpenChannel } from 'lib/sendbird';

const Box = styled.div`
  width: 100%;
  padding: 50px 20px;
  border-radius: 10px;
  background-color: #ffffff;

  & > div:last-child {
    margin-bottom: 0;
  }
`;

const StyledTitleText = styled.h2`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.024em;
  text-align: center;
`;

const ListBox = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 20px auto 8px;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 3px;
    background-color: #d9d9d9;
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`;

const DogInfoBox = styled(FlexBox)`
  align-items: center;
`;

const MatchHistoryItem = styled(FlexBox)`
  max-width: 300px;
  justify-content: space-between;
  align-items: center;
`;

const CircleImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 9px 14px 9px 9px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);

  img {
    border-radius: 50%;
  }
`;

const StyledDescText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.024em;
  text-align: center;
  color: #666666;
`;

const StyledCloseButton = styled.button`
  padding: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.3px;
  text-decoration: underline;
  background-color: #ffffff;
  color: #adadad;
`;

const StyledDogName = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.024em;
  text-align: left;
  color: #666666;
`;

const StyledDogAge = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.024em;
  text-align: left;
  color: #adadad;
`;

const MatchHistoryModal = () => {
  const [payload, hideNotification] = useInAppNotificationStore((state) => [
    state.payload,
    state.hideNotification,
  ]);
  const router = useRouter();
  const parsedData: [Dog, Dog][] = JSON.parse(payload?.data ?? '{}');
  const myDog = parsedData[0][0];
  useReadingAdminChannel(`${myDog.ownerId}`);
  const updateChatGreetingData = useMatchInfoStore((state) => state.updateData);

  const handleClickEnterChat = async (opponentId: string) => {
    try {
      pushEventToGTM('click_enter_the_chat_after_both_saved', { userId: opponentId });
      const channel = await createOrOpenChannel(`${myDog.ownerId}`, opponentId);
      router.push(`/chat/${channel.url}?origin=match`);
      hideNotification();
    } catch (e) {
      console.error(e);
      alert('Failed to enter chat room.');
    }
  };

  return (
    <FullModal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 20px' }}
      onClickBlank={hideNotification}
    >
      <Box onClick={(e) => e.stopPropagation()}>
        <StyledTitleText>It’s a match!</StyledTitleText>
        <StyledDescText>
          {parsedData.length} dog{parsedData.length > 1 ? 's' : ''}{' '}
          {parsedData.length > 1 ? 'are' : 'is'} matched with you!
        </StyledDescText>
        <ListBox>
          {parsedData.map((data) => {
            const opponentDog = data[1];

            return (
              <MatchHistoryItem key={opponentDog.id}>
                <DogInfoBox>
                  <CircleImageWrapper>
                    <Image
                      src={opponentDog.coverPhotoUrl!}
                      width={40}
                      height={40}
                      loader={imageKitLoader}
                      alt={`${opponentDog.name}`}
                      objectFit="cover"
                    />
                  </CircleImageWrapper>
                  <div>
                    <StyledDogName>{opponentDog.name}</StyledDogName>
                    <StyledDogAge>
                      {calculateAgeByAccuracy(opponentDog.dateOfBirth, opponentDog.dobAccuracy)}
                      &nbsp;
                      {opponentDog.gender === Gender.BOY ? 'boy' : 'girl'}
                    </StyledDogAge>
                  </div>
                </DogInfoBox>
                <div style={{ marginRight: '5px' }}>
                  <Button
                    style={{
                      height: 'max-content',
                      padding: '8px',
                      fontSize: '10px',
                      lineHeight: '15px',
                      borderRadius: '6px',
                    }}
                    onClick={() => {
                      updateChatGreetingData({
                        opponentDogName: opponentDog.name!,
                        opponentDogImageUrl: opponentDog.coverPhotoUrl!,
                        myDogImageUrl: myDog.coverPhotoUrl!,
                      });
                      handleClickEnterChat(`${opponentDog.owner?.id}`);
                    }}
                  >
                    Enter chat
                  </Button>
                </div>
              </MatchHistoryItem>
            );
          })}
        </ListBox>
        <FlexBox>
          <StyledCloseButton onClick={hideNotification}>I’ll check it later!</StyledCloseButton>
        </FlexBox>
      </Box>
    </FullModal>
  );
};

export default MatchHistoryModal;
