import styled from 'styled-components';

export const TagArea = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;
  width: 100%;
`;

export const Tag = styled.li`
  box-sizing: border-box;
  display: block;
  align-items: center;
  padding: 6px 10px;
  margin: 0px 4px 4px 0px;
  border: 0.8px solid #dadada;
  border-radius: 5.59633px;
  font-weight: 400;
  font-size: 12px;
  line-height: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  white-space: nowrap;
`;

export const EtcTag = styled(Tag)`
  background: #eeeeee;
  color: #000;
  margin: 0px 0 4px;
`;
