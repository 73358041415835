import styled from 'styled-components';

import useImageProgressStore from 'src/states/image-progress';

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  border-radius: inherit;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ProgressBar = styled.div`
  width: 56px;
  padding: 2px;
  border-radius: 7px;
  background-color: #000;
`;

const StyledProgress = styled.span<{ progress: number }>`
  display: block;
  width: ${(props) => props.progress}%;
  height: 10px;
  background-color: #ffeca9;
  border-radius: 7px;
  transition: width 0.2s;
`;

const ImageUploadProgressBar = () => {
  const progress = useImageProgressStore((state) => state.progress);

  return (
    <Box>
      <ProgressBar>{progress && <StyledProgress progress={progress} />}</ProgressBar>
    </Box>
  );
};

export default ImageUploadProgressBar;
