import { DogBehavior, DogType } from 'src/resources/self-pup-test-results';
import { User } from 'src/types/user';

export const enum Gender {
  BOY = 'male',
  GIRL = 'female',
}

export const enum DateAccuracy {
  NONE = 'none',
  YEAR = 'year',
  MONTH = 'month',
  DAY = 'day',
}

export enum TagCategory {
  PLAYSTYLE = 'Playstyle',
  FOODS = 'Foods',
  ACTIVITIES = 'Activities',
  HABITS = 'Habits',
  PERSONALITY = 'Personality',
  ENERGY_LEVEL = 'Energy Level',
  PERSONALITY_V2 = 'Personality-v2',
}

export enum EnergyLevel {
  LOW = 'Low-energy',
  MEDIUM = 'Medium-energy',
  HIGH = 'High-energy',
}

export interface Tag {
  createdAt: string;
  id: number;
  name: string;
  category: TagCategory;
  isPreset: boolean;
}

export interface SelfPupTestResultItem {
  Tscale: number;
  selects: number[];
}

export interface Dog {
  ownerId: number;
  hashedId: string;
  slug: string;
  alias: string | null;
  coverPhotoUrl: string | null;
  name: string | null;
  gender: Gender | null;
  isNeuteredSpayed: boolean | null;
  breed: string | null;
  weight: number | null;
  dateOfBirth: string | null;
  dobAccuracy: DateAccuracy | null;
  dateOfAdopted: string | null;
  doaAccuracy: DateAccuracy | null;
  cardBgColor: string | null;
  coordinates: string | null;
  tags: Tag[];
  ownTags: Tag[];
  bio: string | null;
  lookFor: string | null;
  skipSocialUrls: boolean;
  instagram: string | null;
  facebook: string | null;
  youtube: string | null;
  twitter: string | null;
  tiktok: string | null;
  metaImageUrl: string | null;
  id: number;
  createdAt: string;
  isProfileReady: boolean;
  isSaved: boolean;
  isLiked: boolean;
  likes: number;
  isVaccinated: boolean | null;
  owner?: User;
  type: DogType | null;
  selfPupTestResult: {
    [DogBehavior.UNFAMILIAR_DOG]: SelfPupTestResultItem;
    [DogBehavior.CHASING]: SelfPupTestResultItem;
    [DogBehavior.DROPPING_OBJECT]: SelfPupTestResultItem;
    [DogBehavior.LOUD_NOISE]: SelfPupTestResultItem;
    [DogBehavior.UNFAMILIAR_PERSON]: SelfPupTestResultItem;
  } | null;
  t1: number | null;
  t2: number | null;
  t3: number | null;
  t4: number | null;
  t5: number | null;
  uuid: string | null;
}

export interface OnboardingStep {
  done: boolean;
  dog: {
    [key in keyof Dog]?: Dog[key];
  };
}

export interface OnboardingData {
  done: boolean;
  currentStepIdx: number;
  steps: OnboardingStep[];
}

export const DogCardColors = [
  '#FFB2B2',
  '#B6F7B4',
  '#C7B4FF',
  '#FFF282',
  '#A9F7E4',
  '#FFCD9E',
  '#9EC9F7',
  '#F7C7E4',
] as const;

export interface RecommendDogs {
  [key: string]: {
    advice: string;
    description: string;
    tScale: number;
    dogs: Dog[];
  };
}
