import styled from 'styled-components';

const Container = styled.div`
  /* position: relative; */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface Props {
  children: React.ReactNode;
}

const GlobalLayout = ({ children }: Props) => {
  return <Container id={'main-container'}>{children}</Container>;
};

export default GlobalLayout;
