import resources from 'src/resources';
import { TScaleLevel } from 'src/utils/self-pup-test';

export enum DogType {
  ASSERTIVE_CONTROLLER = 'Assertive Controller',
  LONE_WOLF = 'The Lone Wolf',
  SOCIAL_BUTTERFLY = 'The Social Butterfly',
  DOGGY_PLEASER = 'The Doggy Pleaser',
  RELENTLESS_DEFENDER = 'The Relentless Defender',
  CAUTIOUS_WORRIER = 'Cautious Worrier',
}

export const enum DogBehavior {
  UNFAMILIAR_DOG = '1',
  UNFAMILIAR_PERSON = '5',
  LOUD_NOISE = '4',
  DROPPING_OBJECT = '3',
  CHASING = '2',
}

export const DogTypeContent = {
  [DogType.ASSERTIVE_CONTROLLER]: {
    illust: resources.images.tAssertiveController,
    cardColor: '#B6F7B4',
    metaImage:
      'https://momo-public-img.s3.us-west-1.amazonaws.com/meta/meta-assertive-controller.png',
    title: 'Go-getter',
    hashTags: ['forceful', 'confident', 'attention_seeking'],
    summary: [
      {
        text: 'The ',
        style: 'none',
      },
      {
        text: 'Go-getter',
        style: 'bold',
      },
      {
        text: ' likes to feel in control of food and attention. However, this boss-like personality may lead to obsession, so it is important to understand why they are assertive.',
        style: 'none',
      },
    ],
    recommend:
      'We recommend meeting dogs who have similar personality results or Labrador Retrievers, German Shepherds, Dachshunds, and Beagles that typically get along well with a Go-getter.',
    lifeHacks: [
      'Get regular exercise: Because of their high-energy nature, Go-getters often don’t have an off-switch. Regular energy consuming play is recommended.',
      'Mental stimulation: It is important to provide an enriched environment at home, with plenty of safe interactive toys and ways for a Go-getter to get the stimulation they require.',
      'Teach to be relaxed: Go-getters can be exhausting to handle if you don’t help them learn how to relax and have quiet time. In fun and exciting situations like getting treats or going for a walk, try to make them sit and get calm first.',
      'Positive training: Positive reinforcement training is very effective for Go-getters, as they respond well to rewards.',
    ],
  },
  [DogType.LONE_WOLF]: {
    illust: resources.images.tLoneWolf,
    cardColor: '#9EC9F7',
    metaImage: 'https://momo-public-img.s3.us-west-1.amazonaws.com/meta/meta-lone-wolf.png',
    title: 'Old Soul',
    hashTags: ['solitary', 'self_reliant'],
    summary: [
      {
        text: 'The ',
        style: 'none',
      },
      {
        text: 'Old Soul',
        style: 'bold',
      },
      {
        text: ' often prefers spending time alone, however, there typically is one person they create a strong attachment to. Despite being more independent dogs, it is still essential to regularly interact and play with Old Souls.',
        style: 'none',
      },
    ],
    recommend:
      'We recommend meeting dogs who have similar personality results or Greyhounds, Whippets, Poodles, Afghan Hounds, and Salukis that typically get along well with an Old Soul.',
    lifeHacks: [
      'Calm energy: Old Souls can spread calmness among other dogs, almost like a beacon of peace. Have playdates with our high energy dogs to help them learn how to be calm!',
      'Patient training: Old Souls can be tricky to train beyond basic obedience,so it’s important to be patient. Give them the time they need to learn new skills or tricks.',
      'Independent: An Old Soul dog will often have their own agenda or plan for how things should happen. This can sometimes lead to trouble, so make sure to give them space when needed and be willing to be flexible with them.',
    ],
  },
  [DogType.SOCIAL_BUTTERFLY]: {
    illust: resources.images.tSocialButterfly,
    cardColor: '#FFF282',
    metaImage: 'https://momo-public-img.s3.us-west-1.amazonaws.com/meta/meta-social-butterfly.png',
    title: 'Social Butterfly',
    hashTags: ['forceful', 'confident', 'attention_seeking'],
    summary: [
      {
        text: 'The ',
        style: 'none',
      },
      {
        text: 'Social Butterfly',
        style: 'bold',
      },
      {
        text: ' is extremely friendly to dogs and people, but often has a difficult time accepting boundaries or personal space. The good news is that making new pup friends is not an issue.',
        style: 'none',
      },
    ],
    recommend:
      'We recommend interacting with dogs who have similar personality results or Pugs, French Bulldogs, Fox Terriers and Bichon Frises that typically get along well with a Social Butterfly.',
    lifeHacks: [
      'Give affection: Social Butterflies love to be in the spotlight, whether out in public or at home. Provide them with lots of affection, especially as rewards for behaving well.',
      'New tricks: Social Butterflies tend to be smart dogs that pick up new tricks quickly. They are very willing to listen and please because of their desire for attention. Try teaching many new tricks to keep them stimulated!',
      'Teach generosity: A Social Butterfly can sometimes become obsessive, especially with receiving attention. Be careful not to spoil them too much and if they start becoming aggressive in situations like having to share attention, stop rewarding them immediately before the behavior gets worse.',
    ],
  },
  [DogType.DOGGY_PLEASER]: {
    illust: resources.images.tDoggyPleaser,
    cardColor: '#A9F7E4',
    metaImage: 'https://momo-public-img.s3.us-west-1.amazonaws.com/meta/meta-dog-pleaser.png',
    title: 'Doggy Pleaser',
    hashTags: ['gentle', 'friendly', 'submissive'],
    summary: [
      {
        text: 'The ',
        style: 'none',
      },
      {
        text: 'Doggy Pleaser',
        style: 'bold',
      },
      {
        text: ' is a very gentle dog who is not likely to get in fights or be controlling over food or attention. However, a Doggy Pleaser can be a bit too submissive with assertive dogs, which is not always beneficial for stress and well-being.',
        style: 'none',
      },
    ],
    recommend:
      'We recommend meeting dogs who have similar personality results or King Charles Cavalier Spaniels, Golden Retrievers, and Boston Terriers that typically get along with a Doggy Pleaser.',
    lifeHacks: [
      'Cuddling is a must: Sensitive and highly attached, a Doggy Pleaser’s favorite thing in the world is a warm, loving cuddle.',
      'Forgive occasional mischief: Sometimes Doggy Pleasers can get a bit over excited and might accidentally make a mess or break something.',
      'Teach politeness: Work together with a Doggy Pleaser to help them learn good manners with other dogs and people. They are also good at learning new tricks because of their desire to please!',
    ],
  },
  [DogType.RELENTLESS_DEFENDER]: {
    illust: resources.images.tRelentlessDefender,
    cardColor: '#F7C7E4',
    metaImage:
      'https://momo-public-img.s3.us-west-1.amazonaws.com/meta/meta-relentless-defender.png',
    title: 'Guardian',
    hashTags: ['defensive', 'assertive', 'protective'],
    summary: [
      {
        text: 'The ',
        style: 'none',
      },
      {
        text: 'Guardian',
        style: 'bold',
      },
      {
        text: ' can be highly protective of their private spaces. Be cautious when introducing confident dogs as fights can readily break out.',
        style: 'none',
      },
    ],
    recommend:
      'We recommend meeting Doggy Pleasers who are very gentle to other dogs or generally friendlier breeds like King Charles Cavalier Spaniels, Golden Retrievers, and Boston Terriers.',
    lifeHacks: [
      'Reduce nerves: Guardians can try to do everything to protect their own possessions, which might include you.Try to reduce situations where they feel overly protective of their things or their parents.',
      'Slow Socialization: Guardians need to learn that it’s not okay to show aggression towards others, so take the time to slowly introduce more social interactions and don’t push their boundaries too quickly.',
      'Check mental health: Although Guardians may seem intimidating to others, many of them are absolute teddy bears inside. If their behavior is not well, look first to environmental factors that might be causing extra stress.',
    ],
  },
  [DogType.CAUTIOUS_WORRIER]: {
    illust: resources.images.tCautiousWorrier,
    cardColor: '#C7B4FF',
    metaImage: 'https://momo-public-img.s3.us-west-1.amazonaws.com/meta/meta-cautious-worrier.png',
    title: 'Love Wisher',
    hashTags: ['anxious', 'insecure', 'nervous', 'shy'],
    summary: [
      {
        text: 'The ',
        style: 'none',
      },
      {
        text: 'Love Wisher',
        style: 'bold',
      },
      {
        text: ' requires lots of love and affection. The good news is that with patience and lots of love, a Love Wisher can gain confidence and live a stress free life.',
        style: 'none',
      },
    ],
    recommend:
      'We recommend meeting Doggy Pleasers who are very gentle to other dogs or generally gentle breeds like King Charles Cavalier Spaniels, Golden Retrievers, and Boston Terriers.',
    lifeHacks: [
      'Earning trust: For a healthy and good relationship with a Love Wisher, it is essential to earn their complete trust. This may take some time and patience, but once achieved, you’ll be rewarded with a deep and lasting bond.',
      'Encouragement: Some Love Wishers might be quiet or shy, but they still know what they like. Don’t force, but encourage them to show their preferences.',
      'Regular check-ups: A Love Wisher has the tendency to hide their health problems or pain, so it’s important to keep an eye on them with regular check-ups.',
    ],
  },
};

export const ActionSteps = {
  [DogBehavior.UNFAMILIAR_DOG]: {
    [TScaleLevel.LOW]:
      'If your dog is overreacting to other dogs, the first step is to prevent your dog from having negative experiences that may build upon the fear that exists in a deeper mind. Give your dog a safe buffer zone on walks and have your dog’s favorite treats handy so when a dog approaches give your dog a treat. When the other dog walks away, stop giving the treats. This begins conditioning your dog that good things occur when they meet or interact with other dogs.',
    [TScaleLevel.MEDIUM]:
      'Understanding your dog’s behavior and how to help your dog overcome any challenges will be important in having a well-adjusted canine companion. Pairing the meeting and interaction with other dogs that match the energy is beneficial.',
    [TScaleLevel.HIGH]:
      'Your dog has a high level of doggy sociability! Proper socialization and monitoring of all interactions with unknown dogs not only prevent your dog from being fearful of new challenges, but also make your dog one of the most friendly dogs in your town.',
  },
  [DogBehavior.UNFAMILIAR_PERSON]: {
    [TScaleLevel.LOW]:
      'Fear of strangers should be managed very carefully. Try to stay friendly and relaxed when you and your dog meet new people. It’s important to allow your dog to approach new people in his own time. For training, prepare new visitors at home and have that person to toss treats gently on the floor during the visit. With consistent exercise, your dog may slowly begin to associate strangers with rewards.',
    [TScaleLevel.MEDIUM]:
      'Some dogs may develop a fear of strangers, particularly men, after a negative experience. A person wearing hats or bulky clothing may induce a challenge. Some dogs may not understand a child’s noises or jerky, uncoordinated movements or their small size. Try your best to expose your dog to people of all ages and appearances.',
    [TScaleLevel.HIGH]:
      'Your dog has a high score of interaction with humans! Dogs that have experienced fear and anxiety for long periods of time may be harder to treat than dogs who have recently developed the trait, so be generous with helping the other dogs!',
  },
  [DogBehavior.LOUD_NOISE]: {
    [TScaleLevel.LOW]:
      'Some dogs have sound phobias to loud noises like fireworks, thunderstorms, gunshots, and firecrackers. Give your dog a space of its own when under these circumstances. It also helps your dog to know where to go when left alone. Applying a thunder shirt or spraying calming doggy pheromones in the safe spot is a great idea.',
    [TScaleLevel.MEDIUM]:
      'The degree of fear of loud noises differs from dog to dog. Some may just have a mild reaction to the thunder. Give your dog a space of its own. It also helps your dog to know where to go when left alone. Applying a thunder shirt or spraying calming doggy pheromones in the safe spot is a great idea.',
    [TScaleLevel.HIGH]:
      'Your dog has a high tolerance of loud noises! Always introduce your dog to new experiences slowly and make it positive by rewarding them with a treat or the favorite toy.',
  },
  [DogBehavior.DROPPING_OBJECT]: {
    [TScaleLevel.LOW]:
      'Some dogs develop an overreaction of a particular object. With overreaction of specific objects, it often comes down to the dog being afraid of something they aren’t familiar with. Help your dog to see the item as being itself instead of a thing to attack, by slowly and repeatedly introducing the object.',
    [TScaleLevel.MEDIUM]:
      'Dogs can sometimes develop fear as a result of negative experiences. Sudden flashing lights, balloons, shiny objects and sometimes even foliage can trigger your dog. A good way to prevent these is to provide as many positive social experiences as you can.',
    [TScaleLevel.HIGH]:
      'Your dog has a high adaptability to the environment! Benefit the neighborhood by socialization with other dogs who have problems with adaptability. Always use positive reinforcement to praise and reward your dog for helping others.',
  },
  [DogBehavior.CHASING]: {
    [TScaleLevel.LOW]:
      'If your dog is already chasing something, don’t chase after him. It will only encourage your pup to think you are playing and that will likely prolong the chase. Leashes and treats are your friends when trying to train your dog not to chase. Teach them to remain calm even when exciting things happen but to wait until they are okay to be released.',
    [TScaleLevel.MEDIUM]:
      'Chasing is a natural canine instinct, especially among herding dogs. Training your dog to build impulse control and avoid distractions is a good way to curb chasing. The chasing is a highly self-rewarding behavior, it can be challenging if your dog enjoys it too much.',
    [TScaleLevel.HIGH]:
      'Such a strong self-controlled dog! The ability to get your dog’s full attention is an important part of bonding as well as your dog’s training. As your dog gets better at focusing on you, make the distractions more difficult and try increasing distance gradually between you and your dog. Highly focused dog will build a greater bonding with you.',
  },
};

export const tScaleToGraphDataMapper = {
  origin: [0, 0.66, 0.75, 1.5, 2, 3.25, 4, 6, 6.5, 8.66, 12, 26],
  converted: [0, 0.5, 1, 1.5, 2, 2.5, 0, 3.5, 4, 4.5, 5, 5.5, 6],
} as const;
