export enum TScaleLevel {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export const getTScaleLevel = (value: number) => {
  if (0 <= value && value <= 0.75) {
    return TScaleLevel.LOW;
  } else if (0.83 <= value && value <= 1.5) {
    return TScaleLevel.MEDIUM;
  } else if (2 <= value && value <= 3) {
    return TScaleLevel.HIGH;
  }
};
