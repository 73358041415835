import { ImageLoaderProps } from 'next/legacy/image';

type ImageKitLoaderParams = {
  src: string;
  width: number;
  height?: number;
  quality?: number;
  forceJpg?: boolean;
  foFace?: boolean;
};

export const imageKitLoader = ({
  src,
  width,
  height,
  quality,
  forceJpg,
  foFace,
}: ImageKitLoaderParams) => {
  // if no src, return empty string
  if (!src) {
    return '';
  }

  // Define the original root directory and the replacement
  const originalRoots = [
    'https://s3.us-west-1.amazonaws.com/photos.meetmy.dog/imagekit',
    'https://photos.meetmy.dog.s3.us-west-1.amazonaws.com/imagekit',
    'https://mmdc-blog-aws-s3-images-bucket.s3.us-west-1.amazonaws.com', // blog
  ];
  const replacementRoot = 'https://ik.imagekit.io/lua6g3xujil';

  if (src.startsWith(replacementRoot)) {
    return src;
  }

  // Find the original root directory in the src
  const originalRoot = originalRoots.reduce((acc, root) => {
    if (src.startsWith(root)) {
      return root;
    }
    return acc;
  }, '');

  if (!originalRoot) {
    return src;
  }

  // Replace the original root directory in the src with the new root directory
  src = src.replace(originalRoot, replacementRoot);

  // Prepare the transformation parameters
  const params = [];
  if (width) {
    params.push(`w-${width}`);
  }
  if (height) {
    params.push(`h-${height}`);
  }
  if (quality) {
    params.push(`q-${quality}`);
  }
  if (forceJpg) {
    params.push('f-jpg');
  }
  if (foFace) {
    params.push('fo-face');
  }
  const paramsString = params.join(',');

  // Ensure there's no trailing slash in the replacement root for a clean URL
  return `${src}?tr=${paramsString}`;
};
