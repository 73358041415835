import useSWR from 'swr';
import Cookies from 'universal-cookie';
import { AxiosError } from 'axios';

import { getUserToken } from 'src/utils/auth';
import { getMyData } from 'src/api/auth';
import { EmptyTokenError } from 'src/utils/error';

export const myDataFetcher = async (args: { url: string; token: string }) => {
  if (!args.token) {
    throw new EmptyTokenError();
  }

  try {
    const me = await getMyData(args.token);
    return me;
  } catch (err) {
    const e = err as AxiosError;
    if (e.response?.status === 401) {
      const cookies = new Cookies();
      cookies.remove('u_t', { path: '/' });
    }
    throw err;
  }
};

const useAuth = () => {
  const { data, error, mutate } = useSWR(
    { url: '/auth/me', token: getUserToken() },
    myDataFetcher,
    { revalidateOnFocus: false },
  );

  return {
    me: data ?? null,
    isLoading: !data && !error,
    error,
    mutate,
  };
};

export default useAuth;
