import { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import * as amplitude from '@amplitude/analytics-browser';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';

import GlobalStyles from 'src/styles/GlobalStyles';
import GlobalLayout from 'src/components/layouts/GlobalLayout';
import TopToastMessage from 'src/components/modals/TopToastMessage';
import NewChatNotificationContainer from 'src/components/chat/NewChatNotificationContainer';
import BottomToastMessage from 'src/components/modals/BottomToastMessage';
import AppLaunchModal from 'src/components/modals/AppLaunchModal';
import AdminGlobalStyles from 'src/styles/AdminGlobalStyles';

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const { pathname } = useRouter();
  const [isAdminOrIntroOrBlog, setIsAdminOrIntroOrBlog] = useState<boolean>(true);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  useEffect(() => {
    amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ?? '');
  }, []);

  useEffect(() => {
    if (pathname) {
      const isAdminOrIntroOrBlog =
        pathname.slice(0, 6) === '/admin' ||
        pathname.slice(0, 6) === '/intro' ||
        pathname.slice(0, 5) === '/blog';
      setIsAdminOrIntroOrBlog(isAdminOrIntroOrBlog);
    }
  }, [pathname]);

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      auth: process.env.NEXT_PUBLIC_GTM_AUTH,
    });
  }, []);

  useEffect(() => {
    return () => {
      window.OneSignal = undefined;
    };
  }, []);

  if (isAdminOrIntroOrBlog) {
    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
          />
        </Head>
        <AdminGlobalStyles />
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <GlobalLayout>
              {getLayout(<Component {...pageProps} />)}
              <TopToastMessage top="60px" />
              <NewChatNotificationContainer />
              <BottomToastMessage />
            </GlobalLayout>
          </Hydrate>
        </QueryClientProvider>
      </>
    );
  }
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <GlobalLayout>
            {getLayout(<Component {...pageProps} />)}
            <TopToastMessage top="60px" />
            <NewChatNotificationContainer />
            <BottomToastMessage />
            <AppLaunchModal />
          </GlobalLayout>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
