import { create } from 'zustand';

type AppLaunchModalType = 'default' | 'playdates';

interface AppLaunchModalStore {
  openAppLaunchModal: boolean;
  appLaunchModalType: AppLaunchModalType;
  updateOpenAppLaunchModal: (state: boolean) => void;
  updateAppLaunchModalType: (state: AppLaunchModalType) => void;
}

const useUnreadMessageStore = create<AppLaunchModalStore>((set) => ({
  openAppLaunchModal: false,
  appLaunchModalType: 'default',
  updateOpenAppLaunchModal: (state) => set({ openAppLaunchModal: state }),
  updateAppLaunchModalType: (state) => set({ appLaunchModalType: state }),
}));

export default useUnreadMessageStore;
