import { create } from 'zustand';

interface ImageProgressStore {
  progress: number;
  totalCount: number;
  thumbnail?: string;
  updateProgress: (progress: number, total?: number) => void;
  setThumbnail: (url: string) => void;
  resetProgress: () => void;
}

const useImageProgressStore = create<ImageProgressStore>((set) => ({
  progress: 0,
  totalCount: 0,
  updateProgress: (progress, total = 0) => set({ progress, totalCount: total }),
  setThumbnail: (url) => set({ thumbnail: url }),
  resetProgress: () => set({ progress: 0, thumbnail: undefined, totalCount: 0 }),
}));

export default useImageProgressStore;
