import { GetServerSidePropsContext } from 'next';
import Cookies, { CookieSetOptions } from 'universal-cookie';
import { serialize } from 'cookie';
import IOneSignal from 'react-onesignal';

import { getMyData } from 'src/api/auth';
import TagManager from 'react-gtm-module';

export const getUserToken = (defaultCookies?: string | object | null): string | null => {
  const cookies = new Cookies(defaultCookies);
  return cookies.get('u_t') || null;
};

export const checkLoginServerSide = async (context: GetServerSidePropsContext) => {
  const token = getUserToken(context.req.cookies);

  if (!token) {
    return null;
  }

  try {
    const data = await getMyData(token);
    return data;
  } catch {
    context.res.setHeader('Set-Cookie', [serialize('u_t', '', { maxAge: -1, path: '/' })]);
    return null;
  }
};

export const validatePassword = (password: string) => {
  const isValid =
    password.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/) !== null;

  if (!isValid) {
    throw new Error(
      'Password should be at least 8 characters; contained alphabet, number, special character',
    );
  }
};

export const signOut = () => {
  const OneSignal: typeof IOneSignal = window.OneSignal;
  OneSignal?.push(function () {
    OneSignal.removeExternalUserId();
  });

  const cookies = new Cookies();
  cookies.remove('u_t', { path: '/' });
  cookies.remove('s_t', { path: '/' });
  TagManager.dataLayer({
    dataLayer: {
      event: 'sign_out',
      user_id: undefined,
    },
  });
  location.href = '/';
};

export const checkEmailForm = (email: string) => {
  // validate email format
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    throw new Error('Invalid email address format');
  }
};

const cookieSetOptions: CookieSetOptions = { path: '/', maxAge: 60 * 60 * 24 * 7 };

export const setTokensInCookie = (accessToken: string, sendbirdToken: string) => {
  const cookies = new Cookies();
  cookies.set('u_t', accessToken, cookieSetOptions);
  cookies.set('s_t', sendbirdToken, cookieSetOptions);
};

export const setUserDataForGtmAfterSign = (
  accessToken: string,
  userId: number,
  eventName: string,
) => {
  localStorage.setItem(accessToken, '' + userId);
  TagManager.dataLayer({ dataLayer: { event: eventName, user_id: userId } });
};

export const removeStoredRedirectUrl = () => {
  sessionStorage.getItem('redirectUrlAfterAuth') &&
    sessionStorage.removeItem('redirectUrlAfterAuth');
};
