const AppLaunchModalContent = () => {
  return (
    <>
      Download <b>MMDC</b> app
      <br />
      to discover more
      <br />
      nearby playdates!
    </>
  );
};

export default AppLaunchModalContent;
