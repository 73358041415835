import styled from 'styled-components';

import Spinner from 'src/components/Spinner';

const StyledButton = styled.button<{ size: string; inverted: boolean }>`
  display: flex;
  width: 100%;
  height: ${(props) => (props.size === 'medium' ? '42px' : '52px')};
  padding: 12px 0;
  background-color: #000;
  border-radius: 14px;
  font-size: ${(props) => (props.size === 'medium' ? '14px' : '17px')};
  font-weight: 600;
  line-height: 25.5px;
  letter-spacing: -0.3px;
  align-items: center;
  justify-content: center;
  color: #fff;
  ${(props) =>
    props.inverted
      ? `
        background-color: #fff; 
        border: 1px solid #000;
        color: #000;
      `
      : ''}

  &:disabled {
    background-color: #ccc;
  }
`;

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  type?: 'button' | 'submit';
  form?: string;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  spinnerWidth?: number;
  spinnerHeight?: number;
  size?: 'large' | 'medium';
  inverted?: boolean;
}

const Button = ({
  type,
  form,
  size = 'large',
  onClick,
  disabled,
  loading,
  spinnerHeight,
  spinnerWidth,
  style,
  children,
  inverted = false,
}: Props) => {
  return (
    <StyledButton
      inverted={inverted}
      type={type}
      form={form}
      size={size}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled || loading}
      style={style}
    >
      {loading ? <Spinner width={spinnerWidth ?? 32} height={spinnerHeight ?? 32} /> : children}
    </StyledButton>
  );
};

export default Button;
