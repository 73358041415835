import Image from 'next/legacy/image';

import useInAppNotificationStore from 'src/states/inapp-notification';
import UserMessageNotification from 'src/components/chat/UserMessageNotification';
import MatchModal from 'src/components/modals/MatchModal';
import MatchHistoryModal from 'src/components/modals/MatchHistoryModal';

const NewChatNotificationContainer = () => {
  const [isOpen, payload, mode] = useInAppNotificationStore((state) => [
    state.isOpen,
    state.payload,
    state.mode,
  ]);

  if (!isOpen) {
    return null;
  }

  if (mode === 'message') {
    return <UserMessageNotification />;
  }

  if (mode === 'reaction') {
    const parsedData = JSON.parse(payload?.data ?? '{}');
    return (
      <UserMessageNotification
        content={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image src={parsedData.emojiSrc} width={14} height={14} alt={parsedData.text} />
            <p>&nbsp;/&nbsp;{parsedData.text}</p>
          </div>
        }
      />
    );
  }

  if (mode === 'match_history') {
    return <MatchHistoryModal />;
  }

  if (mode === 'both_liked_notification') {
    return <MatchModal />;
  }
  return null;
};

export default NewChatNotificationContainer;
