import { NextRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import SendBird, { UserMessage } from 'sendbird';

import { Dog } from 'src/types/dog';

export const pushEventToGTM = (triggerName: string, dataLayerObj?: { [variable: string]: any }) => {
  if (!dataLayerObj) {
    TagManager.dataLayer({
      dataLayer: {
        event: triggerName,
      },
    });
  } else {
    TagManager.dataLayer({
      dataLayer: {
        event: triggerName,
        ...dataLayerObj,
      },
    });
  }
};

export const pushDataLayerCardSeenEvent = (dog: Dog, myId: number) => {
  if (dog) {
    pushEventToGTM('card_seen', {
      dogId: dog?.id,
      isMine: dog?.ownerId === myId,
      isDogProfileReady: dog?.isProfileReady,
    });
  }
};

export const pushDataLayerOnboarding = (router: NextRouter, dogId: number) => {
  const currentStep = router.pathname
    .slice(router.pathname.lastIndexOf('/') + 1)
    .replaceAll('-', '_');

  if (router.query.action === 'edit') {
    pushEventToGTM(`card_edit_${currentStep}`, { dogId });
  } else {
    const isFirst = !!router.query.first;
    pushEventToGTM(isFirst ? `onboarding_${currentStep}` : `card_add_${currentStep}`, { dogId });
  }
};

export const pushMessageSentEvent = (
  channelUrl: string,
  lastMessage: UserMessage | null,
  message: UserMessage,
) => {
  const lastMessageSender = lastMessage?.sender;
  const isLastMessageMine = lastMessageSender?.userId === message.sender?.userId;

  pushEventToGTM('send_message', {
    channelUrl,
    messageType: message.customType || 'text',
    opponentLastMessageType: isLastMessageMine ? null : lastMessage?.customType || 'text',
  });
};
