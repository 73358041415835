import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { CSSProperties, useEffect, useState } from 'react';

const Box = styled.div<{ fullWidth?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? 'none' : '480px')};
  min-width: 800px;
  margin: 0 auto;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
  transition: opacity 0.3s, visibility 0.3s;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface Props {
  children: React.ReactNode;
  onClickBlank: () => void;
  style?: CSSProperties;
  className?: string;
  dim?: boolean;
  delayDim?: boolean;
  shouldClose?: boolean;
  fullWidth?: boolean;
}

const showStyle: CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  transition: 'background-color 0.3s ease-out',
  backdropFilter: 'blur(5px)',
  WebkitBackdropFilter: 'blur(5px)',
};

const hideStyle: CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0)',
  transition: 'background-color 0.3s ease-out',
  backdropFilter: 'blur(0)',
  WebkitBackdropFilter: 'blur(0)',
};

const FullModal = ({
  children,
  onClickBlank,
  style,
  className,
  dim = true,
  delayDim = false,
  shouldClose,
  fullWidth = false,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   setIsLoaded(true);
  // }, []);

  useEffect(() => {
    if (delayDim) {
      setTimeout(() => {
        setIsLoaded(dim);
      }, 300);
    } else {
      setIsLoaded(dim);
    }
  }, [dim, delayDim]);

  useEffect(() => {
    if (shouldClose) {
      setIsLoaded(false);
    }
  }, [shouldClose]);

  return createPortal(
    <Box
      onClick={(e) => {
        e.stopPropagation();
        setIsLoaded(false);
        onClickBlank();
      }}
      fullWidth={fullWidth}
      style={dim ? (isLoaded ? showStyle : hideStyle) : {}}
    >
      <Inner className={className} style={style}>
        {children}
      </Inner>
    </Box>,
    document.body,
  );
};

export default FullModal;
