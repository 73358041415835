import React from 'react';

const PlaydatesAppLaunchModalContent = () => {
  return (
    <>
      Ready to host a playdate?
      <br />
      Do it in the
      <br />
      <b>MMDC</b> app!
    </>
  );
};

export default PlaydatesAppLaunchModalContent;
