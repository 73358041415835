import SendBird, { GroupChannel } from 'sendbird';
import { SendBirdSelectors } from 'sendbird-uikit';
import api from 'src/api';
import { getUserToken } from 'src/utils/auth';
import { EmptyTokenError } from 'src/utils/error';
import Cookies from 'universal-cookie';

export const SENDBIRD_ADMIN_ID = 'admin';

export const connectSendbird = async (userId: string) => {
  const cookies = new Cookies();
  const sessionToken = cookies.get('s_t');
  const sb = new SendBird({
    appId: process.env.NEXT_PUBLIC_SENDBIRD_APP_ID,
    localCacheEnabled: true,
  });

  if (sessionToken) {
    await sb.connect(userId, sessionToken as string);
    return sb;
  }

  throw new EmptyTokenError();
};

export const hideSendbirdChannel = async (sdk: SendBirdSelectors.GetSdk, channelUrl: string) => {
  if (sdk) {
    const groupChannel = await sdk.GroupChannel.getChannel(channelUrl);
    await groupChannel.hide(true, true);
  }
};

export const getOpponentFromChannel = (channel: GroupChannel, myId: string) => {
  return channel.members.filter((member) => member.userId !== myId)[0];
};

export const backupChatImageFile = async (messageId: number, photoUrl: string) => {
  const token = getUserToken();
  if (token) {
    await api.patch(
      `/sendbird/message/${messageId}`,
      { photoUrl },
      { headers: { Authorization: `Bearer ${token}` } },
    );
    return;
  }
  throw new EmptyTokenError();
};

export const createOrOpenChannel = async (myId: string, opponentId: string) => {
  const sdk = await connectSendbird(myId);
  const params = new sdk.GroupChannelParams();
  params.isDistinct = true;
  params.addUserId(opponentId);

  const channel = await sdk.GroupChannel.createChannel(params);
  return channel;
};

export const markAsReadChannelMessages = async (myId: string, opponentId: string) => {
  const channel = await createOrOpenChannel(myId, opponentId);
  await channel.markAsRead();
};
