import { create } from 'zustand';

export interface MatchInfoData {
  opponentDogName: string;
  opponentDogImageUrl: string;
  myDogImageUrl: string;
}

export interface MatchInfoStore {
  data: MatchInfoData | null;
  updateData: (data: MatchInfoData) => void;
}

const useMatchInfoStore = create<MatchInfoStore>((set) => ({
  data: null,
  updateData: (data) => set({ data }),
}));

export default useMatchInfoStore;
