import styled from 'styled-components';
import Image from 'next/legacy/image';

import useMessageStore from 'src/states/message';
import { useEffect } from 'react';

const Box = styled.div<{ hasIcon: boolean }>`
  position: fixed;
  top: ${(props) => (props.hasIcon ? 'calc(50% + 52px)' : '50%')};
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  min-height: 30px;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5.6px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  z-index: 999999;

  > div {
    position: absolute;
    top: -52px;
  }

  > p {
    text-align: center;
    line-height: 1.2;
  }
`;

interface Props {
  top?: string;
}

const TopToastMessage = ({ top }: Props) => {
  const [message, icon, mode, hideMessage] = useMessageStore((state) => [
    state.message,
    state.icon,
    state.mode,
    state.hideMessage,
  ]);
  const isOpen = message && mode === 'top';

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => hideMessage(), 2000);
    }
  }, [isOpen, hideMessage]);

  if (isOpen) {
    return (
      <Box hasIcon={!!icon}>
        {icon && (
          <div>
            <Image src={icon} width={52} height={52} alt="icon" />
          </div>
        )}
        <p>{message}</p>
      </Box>
    );
  }

  return null;
};

export default TopToastMessage;
