import { useEffect } from 'react';
import styled from 'styled-components';

import useInAppNotificationStore from 'src/states/inapp-notification';
import resources from 'src/resources';
import UserProfileImage from 'src/components/user-profiles/UserProfileImage';
import { useRouter } from 'next/router';

const Box = styled.div`
  position: absolute;
  bottom: 12px;
  left: calc(50% - 150px);
  width: 300px;
  padding: 12px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 12px 24px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 9999;
`;

const InnerBox = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ProfileImageWrapper = styled.span`
  display: inline-flex;
  width: 39px;
  height: 39px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 3px solid #fff;
`;

const ContentBox = styled.div`
  flex: 1;
  padding: 0 12px;
  overflow: hidden;
`;

const ContentTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCloseButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: #fff;
  background-image: url(${resources.icons.close});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  flex-shrink: 0;
`;

interface Props {
  content?: string | React.ReactNode;
}

const UserMessageNotification = ({ content }: Props) => {
  const [payload, hideNotification] = useInAppNotificationStore((state) => [
    state.payload,
    state.hideNotification,
  ]);
  const router = useRouter();

  const profileImageUrl = payload?.sender?.profileUrl ?? null;
  const name = payload?.sender?.nickname ?? '';

  useEffect(() => {
    const timeOut = setTimeout(() => hideNotification(), 5000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [hideNotification]);

  const handleClick = () => {
    router.push(payload?.channelUrl ? `/chat/${payload.channelUrl}` : '/chat');
    hideNotification();
  };

  return (
    <Box onClick={handleClick}>
      <InnerBox>
        <ProfileImageWrapper>
          <UserProfileImage
            editable={false}
            profileImageUrl={profileImageUrl}
            name={name}
            size={33}
            fontSize={16}
          />
        </ProfileImageWrapper>
        <ContentBox>
          <ContentTitle>{name}</ContentTitle>
          <Content>{content ?? payload?.message}</Content>
        </ContentBox>
        <StyledCloseButton
          onClick={(e) => {
            e.stopPropagation();
            hideNotification();
          }}
        />
      </InnerBox>
    </Box>
  );
};

export default UserMessageNotification;
