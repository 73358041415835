import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const AdminGlobalStyles = createGlobalStyle`
  ${reset}
  * {
    box-sizing: border-box;
  }
  html {
    position: relative;
    width: 100%;
    background-color: #f0f0f0;
    color: #000000;
    font-size: 16px;
  }
  body {
    width: 100%;
    height: 100%;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  #__next{
    width: 100%;
    height: 100%;
    min-width: 320px;
    margin: 0 auto;
  }
  input, button, textarea, pre {
    outline: none;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
  }
  button {
    border: none;
    cursor: pointer;
    user-select: none;
  }
  input {
    -webkit-appearance: none;
  }
`;

export default AdminGlobalStyles;
