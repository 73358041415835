import { create } from 'zustand';

interface UnreadMessageStore {
  hasUnreadMessage: boolean;
  updateHasUnreadMessage: (state: boolean) => void;
}

const useUnreadMessageStore = create<UnreadMessageStore>((set) => ({
  hasUnreadMessage: false,
  updateHasUnreadMessage: (state) => set({ hasUnreadMessage: state }),
}));

export default useUnreadMessageStore;
