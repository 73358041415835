import styled from 'styled-components';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';

import FullModal from 'src/components/modals/FullModal';
import Button from 'src/components/buttons/Button';
import useInAppNotificationStore from 'src/states/inapp-notification';
import { Dog } from 'src/types/dog';
import UserProfileImage from 'src/components/user-profiles/UserProfileImage';
import { imageKitLoader } from 'src/utils/imagekit.loader';
import { pushEventToGTM } from 'src/utils/gtm';
import useMatchInfoStore from 'src/states/match-info';
import useReadingAdminChannel from 'src/hooks/useReadingAdminChannel';
import { createOrOpenChannel } from 'lib/sendbird';
import { Tag, TagArea } from 'src/components/for-you/styled.tag';
import { getCommonTagsWithDogFromFactors } from 'src/utils/tags';

const Box = styled.div`
  padding: 50px 30px;
  border-radius: 10px;
  background-color: #ffffff;

  & > div:last-child {
    margin-bottom: 0;
  }
`;

const StyledTitleText = styled.h2`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.024em;
  text-align: center;
`;

const ContentBox = styled.div`
  margin: 20px 0;
`;

const ImageBox = styled(ContentBox)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageInnerBox = styled.div`
  position: relative;
`;

const CircleImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
`;

const ProfileImageWrapper = styled(CircleImageWrapper)`
  position: absolute;
  bottom: -3px;
  right: -3px;
  border: 3px solid #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
`;

const StyledDescText = styled.p`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.024em;
  text-align: center;
  color: #666666;
`;

const ButtonBox = styled.div`
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
`;

const StyledCloseButton = styled.button`
  margin-top: 8px;
  padding: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.3px;
  text-decoration: underline;
  background-color: #ffffff;
  color: #adadad;
`;

const MatchModal = () => {
  const [payload, hideNotification] = useInAppNotificationStore((state) => [
    state.payload,
    state.hideNotification,
  ]);
  const parsedData = JSON.parse(payload?.data ?? '{}');
  const myDog: Dog = parsedData[0];
  const router = useRouter();
  useReadingAdminChannel(`${myDog.ownerId}`);
  const updateChatGreetingData = useMatchInfoStore((state) => state.updateData);
  const opponentDog: Dog = parsedData[1];
  const commonValue = parsedData[2].factorValues;
  const commonFactor = parsedData[2].factors;
  const commonTags = getCommonTagsWithDogFromFactors(commonFactor, commonValue, opponentDog);

  const handleClickEnterChat = async () => {
    try {
      pushEventToGTM('click_enter_the_chat_after_both_saved', { userId: opponentDog.ownerId });
      updateChatGreetingData({
        opponentDogName: opponentDog.name!,
        opponentDogImageUrl: opponentDog.coverPhotoUrl!,
        myDogImageUrl: myDog.coverPhotoUrl!,
      });
      const channel = await createOrOpenChannel(`${myDog.ownerId}`, `${opponentDog.ownerId}`);
      router.push(`/chat/${channel.url}?origin=match`);
      hideNotification();
    } catch (e) {
      console.error(e);
      alert('Failed to enter chat room.');
    }
  };

  return (
    <FullModal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 20px' }}
      onClickBlank={hideNotification}
    >
      <Box onClick={(e) => e.stopPropagation()}>
        <StyledTitleText>It’s a match!</StyledTitleText>
        <ImageBox>
          <ImageInnerBox>
            <CircleImageWrapper>
              <Image
                src={myDog.coverPhotoUrl!}
                loader={imageKitLoader}
                width={100}
                height={100}
                alt="my dog"
                objectFit="cover"
              />
            </CircleImageWrapper>
            <ProfileImageWrapper style={{ right: '4px' }}>
              <UserProfileImage
                editable={false}
                size={37}
                profileImageUrl={myDog.owner?.profileImageUrl ?? null}
                name={myDog.owner?.name ?? ''}
                fontSize={16}
              />
            </ProfileImageWrapper>
          </ImageInnerBox>
          <ImageInnerBox style={{ marginLeft: '-5px' }}>
            <CircleImageWrapper>
              <Image
                src={opponentDog.coverPhotoUrl!}
                loader={imageKitLoader}
                width={100}
                height={100}
                objectFit="cover"
                alt="opponent dog"
              />
            </CircleImageWrapper>
            <ProfileImageWrapper>
              <UserProfileImage
                editable={false}
                size={37}
                profileImageUrl={opponentDog.owner?.profileImageUrl ?? null}
                name={opponentDog.owner?.name ?? ''}
                fontSize={16}
              />
            </ProfileImageWrapper>
          </ImageInnerBox>
        </ImageBox>
        <ContentBox>
          <StyledDescText>
            You and {opponentDog.owner?.name} have liked each other!
            <br />
            Would you like to start conversation now?
          </StyledDescText>
        </ContentBox>
        <ContentBox>
          <ButtonBox>
            <Button onClick={handleClickEnterChat}>Send message</Button>
            {commonFactor.length === 0 && (
              <StyledCloseButton onClick={hideNotification}>I’ll do it later!</StyledCloseButton>
            )}
          </ButtonBox>
        </ContentBox>
        {commonFactor.length > 0 && (
          <ContentBox>
            <StyledDescText>Here’s what you’ve got in common</StyledDescText>
            <TagArea style={{ width: '280px', margin: '20px 0 14px' }}>
              {commonTags.map((tag, i) => {
                if (tag.match(/[0-9]lbs/g)) {
                  return <Tag key={`common-tag-${tag}-${i}`}>Similar Size</Tag>;
                }
                return <Tag key={`common-tag-${tag}-${i}`}>{tag}</Tag>;
              })}
            </TagArea>
            <ButtonBox>
              <StyledCloseButton onClick={hideNotification}>I’ll do it later!</StyledCloseButton>
            </ButtonBox>
          </ContentBox>
        )}
      </Box>
    </FullModal>
  );
};

export default MatchModal;
