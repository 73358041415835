import React from 'react';
import styled from 'styled-components';
import Image from 'next/legacy/image';

import resources from 'src/resources';
import { imageKitLoader } from 'src/utils/imagekit.loader';
import ImageUploadProgressBar from 'src/components/ImageUploadProgressBar';

const Box = styled.div<{ size: number; fontSize?: number }>`
  position: relative;
  display: flex;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: #c4c4c4;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}` : '40')}px;
  font-weight: 500;
  flex-shrink: 0;
  user-select: none;
`;

const ImageWrapper = styled.label`
  display: flex;
  border-radius: 50px;
  img {
    border-radius: 50%;
  }
`;

const TextWrapper = styled.label`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
`;

const ImageSelector = styled.input`
  width: 0px;
  height: 0px;
  overflow: hidden;
`;

const ImageSelectorLabel = styled.label`
  position: absolute;
  bottom: 4px;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #000000;
  background-image: url(${resources.icons.cameraWhite});
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
`;

interface Props {
  editable: boolean;
  profileImageUrl: string | null;
  name: string;
  size: number;
  fontSize?: number;
  isUploading?: boolean;
  isUpdating?: boolean;
  onSelectImage?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
}

const UserProfileImage = ({
  editable,
  profileImageUrl,
  name,
  size,
  fontSize,
  isUploading,
  isUpdating,
  onSelectImage,
  style,
}: Props) => {
  return (
    <Box size={size} fontSize={fontSize} style={style}>
      {profileImageUrl ? (
        <ImageWrapper htmlFor="profile-image">
          <Image
            src={profileImageUrl}
            width={size}
            height={size}
            alt={`${name} profile`}
            loader={imageKitLoader}
            priority
          />
          {isUploading && <ImageUploadProgressBar />}
        </ImageWrapper>
      ) : (
        <TextWrapper htmlFor="profile-image">{name && name[0].toUpperCase()}</TextWrapper>
      )}
      {editable && (
        <>
          <ImageSelectorLabel htmlFor="profile-image" />
          <ImageSelector
            id="profile-image"
            type="file"
            accept="image/*"
            onChange={onSelectImage}
            disabled={isUpdating || isUploading}
          />
        </>
      )}
    </Box>
  );
};

export default React.memo(UserProfileImage);
