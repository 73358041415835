import axios from 'axios';

export interface APIResponse<D, M = null> {
  data: D;
  meta: M;
}

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
});

export default api;
